define("discourse/plugins/discourse-sift/helpers/sift-high-risk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  // This helper class is checking if the given risk level should be considered high-risk
  // currently this means greater than or equal to a 4
  // TODO: Could be extended to allow a threshold to be passed in, or configured somewhere (settings?)

  // Threshold
  const boundaryRiskLevel = 4;
  function _default(topicValue) {
    return parseInt(topicValue, 10) >= boundaryRiskLevel;
  }
});