define("discourse/plugins/discourse-sift/discourse/templates/connectors/after-reviewable-flagged-post-body/sift", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="sift-classification">
    {{#if this.model.sift_response}}
      {{#each-in this.model.sift_response.topics as |topicKey topicValue|}}
        {{! following code line is a custom 'if' condition check via a helper to evaluate if the topicValue }}
        {{#if (sift-high-risk topicValue)}}
          <span class="sift-topic sift-topic-risk-{{topicValue}}">
            <span class="sift-topic-{{topicKey}}"></span>
            <span class="sift-risk">{{topicValue}}</span>
          </span>
        {{/if}}
      {{/each-in}}
  
      <div>
        {{#if this.model.sift_response.tokenized_solution}}
          {{#each this.model.sift_response.tokenized_solution.slots as |slot|}}
            {{#if slot.is_pre}}
              {{! TODO: sometimes pre should be shown? (vertical filter, etc }}
            {{~else~}}
              <span
                class="sift-classification-unit sift-risk-{{slot.risk}}
                  {{if
                    slot.join_left
                    (concat 'sift-unit-join-left-risk-' slot.risk)
                  }}
                  {{if
                    slot.join_right
                    (concat 'sift-unit-join-right-risk-' slot.risk)
                  }}"
              >
                {{if slot.original slot.original slot.text}}
              </span>
            {{/if}}
          {{/each}}
        {{~else~}}
          <span>{{i18n "sift.no_sift_tokenized"}}</span>
        {{/if}}
      </div>
    {{~else~}}
      <span>{{i18n "sift.no_sift_info"}}</span>
    {{/if}}
  </div>
  */
  {
    "id": "XGriwC7Y",
    "block": "[[[10,0],[14,0,\"sift-classification\"],[12],[1,\"\\n\"],[41,[30,0,[\"model\",\"sift_response\"]],[[[42,[28,[37,2],[[30,0,[\"model\",\"sift_response\",\"topics\"]]],null],null,[[[41,[28,[37,3],[[30,1]],null],[[[1,\"        \"],[10,1],[15,0,[29,[\"sift-topic sift-topic-risk-\",[30,1]]]],[12],[1,\"\\n          \"],[10,1],[15,0,[29,[\"sift-topic-\",[30,2]]]],[12],[13],[1,\"\\n          \"],[10,1],[14,0,\"sift-risk\"],[12],[1,[30,1]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[1,2]],null],[1,\"\\n    \"],[10,0],[12],[1,\"\\n\"],[41,[30,0,[\"model\",\"sift_response\",\"tokenized_solution\"]],[[[42,[28,[37,4],[[28,[37,4],[[30,0,[\"model\",\"sift_response\",\"tokenized_solution\",\"slots\"]]],null]],null],null,[[[41,[30,3,[\"is_pre\"]],[[],[]],[[[10,1],[15,0,[29,[\"sift-classification-unit sift-risk-\",[30,3,[\"risk\"]],\"\\n                \",[52,[30,3,[\"join_left\"]],[28,[37,5],[\"sift-unit-join-left-risk-\",[30,3,[\"risk\"]]],null]],\"\\n                \",[52,[30,3,[\"join_right\"]],[28,[37,5],[\"sift-unit-join-right-risk-\",[30,3,[\"risk\"]]],null]]]]],[12],[1,\"\\n              \"],[1,[52,[30,3,[\"original\"]],[30,3,[\"original\"]],[30,3,[\"text\"]]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]]]],[3]],null]],[]],[[[10,1],[12],[1,[28,[35,6],[\"sift.no_sift_tokenized\"],null]],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13]],[]],[[[10,1],[12],[1,[28,[35,6],[\"sift.no_sift_info\"],null]],[13],[1,\"\\n\"]],[]]],[13]],[\"topicValue\",\"topicKey\",\"slot\"],false,[\"if\",\"each\",\"-each-in\",\"sift-high-risk\",\"-track-array\",\"concat\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-sift/discourse/templates/connectors/after-reviewable-flagged-post-body/sift.hbs",
    "isStrictMode": false
  });
});