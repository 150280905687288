define("discourse/plugins/discourse-sift/discourse/templates/connectors/site-map-links/sift-review", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.currentUser.staff}}
    <li>
      {{#link-to route="adminPlugins.sift"}}
        {{i18n "sift.title"}}
        {{#if this.currentUser.sift_review_count}}
          <span class="badge-notification flagged-posts">
            {{this.currentUser.sift_review_count}}
          </span>
        {{/if}}
      {{/link-to}}
    </li>
  {{/if}}
  */
  {
    "id": "uMdUBNA+",
    "block": "[[[41,[30,0,[\"currentUser\",\"staff\"]],[[[1,\"  \"],[10,\"li\"],[12],[1,\"\\n\"],[6,[39,1],null,[[\"route\"],[\"adminPlugins.sift\"]],[[\"default\"],[[[[1,\"      \"],[1,[28,[35,2],[\"sift.title\"],null]],[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"sift_review_count\"]],[[[1,\"        \"],[10,1],[14,0,\"badge-notification flagged-posts\"],[12],[1,\"\\n          \"],[1,[30,0,[\"currentUser\",\"sift_review_count\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[]]]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"link-to\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-sift/discourse/templates/connectors/site-map-links/sift-review.hbs",
    "isStrictMode": false
  });
});